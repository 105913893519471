<template>
  <div class="place">
    <div class="lodad" v-if="isLoad">
      <Loader />
    </div>
    <div class="content" v-else>
      <div class="header">
        <div class="main__image">
          <img :src="`${data.photo_main}?s=${timestamp}`" />
        </div>
        <div class="main__title">
          <p class="name">{{ data.name }}</p>
          <p class="category">{{ data.category }}</p>
        </div>
      </div>
      <section>
        <div class="card" data-aos="fade-up">
          <div class="card__item" v-if="data.adress != ''">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512">
              <path d="M12,6a4,4,0,1,0,4,4A4,4,0,0,0,12,6Zm0,6a2,2,0,1,1,2-2A2,2,0,0,1,12,12Z" />
              <path
                d="M12,24a5.271,5.271,0,0,1-4.311-2.2c-3.811-5.257-5.744-9.209-5.744-11.747a10.055,10.055,0,0,1,20.11,0c0,2.538-1.933,6.49-5.744,11.747A5.271,5.271,0,0,1,12,24ZM12,2.181a7.883,7.883,0,0,0-7.874,7.874c0,2.01,1.893,5.727,5.329,10.466a3.145,3.145,0,0,0,5.09,0c3.436-4.739,5.329-8.456,5.329-10.466A7.883,7.883,0,0,0,12,2.181Z"
              />
            </svg>
            <p>{{ data.adress }}</p>
            <span>адерса</span>
          </div>
          <div class="card__item" v-if="data.phone != ''">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512">
              <path
                d="M13,1a1,1,0,0,1,1-1A10.011,10.011,0,0,1,24,10a1,1,0,0,1-2,0,8.009,8.009,0,0,0-8-8A1,1,0,0,1,13,1Zm1,5a4,4,0,0,1,4,4,1,1,0,0,0,2,0,6.006,6.006,0,0,0-6-6,1,1,0,0,0,0,2Zm9.093,10.739a3.1,3.1,0,0,1,0,4.378l-.91,1.049c-8.19,7.841-28.12-12.084-20.4-20.3l1.15-1A3.081,3.081,0,0,1,7.26.906c.031.031,1.884,2.438,1.884,2.438a3.1,3.1,0,0,1-.007,4.282L7.979,9.082a12.781,12.781,0,0,0,6.931,6.945l1.465-1.165a3.1,3.1,0,0,1,4.281-.006S23.062,16.708,23.093,16.739Zm-1.376,1.454s-2.393-1.841-2.424-1.872a1.1,1.1,0,0,0-1.549,0c-.027.028-2.044,1.635-2.044,1.635a1,1,0,0,1-.979.152A15.009,15.009,0,0,1,5.9,9.3a1,1,0,0,1,.145-1S7.652,6.282,7.679,6.256a1.1,1.1,0,0,0,0-1.549c-.031-.03-1.872-2.425-1.872-2.425a1.1,1.1,0,0,0-1.51.039l-1.15,1C-2.495,10.105,14.776,26.418,20.721,20.8l.911-1.05A1.121,1.121,0,0,0,21.717,18.193Z"
              />
            </svg>
            <a :href="'tel:' + data.phone">{{ data.phone }}</a>
            <span>телефон</span>
          </div>
          <div class="card__item" v-if="data.time != ''">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512">
              <path
                d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm0,22A10,10,0,1,1,22,12,10.011,10.011,0,0,1,12,22Z"
              />
              <path
                d="M12,6a1,1,0,0,0-1,1v4.325L7.629,13.437a1,1,0,0,0,1.062,1.7l3.84-2.4A1,1,0,0,0,13,11.879V7A1,1,0,0,0,12,6Z"
              />
            </svg>
            <p>{{ data.time }}</p>
            <span>час</span>
          </div>
          <div class="card__item" v-if="data.link != ''">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512">
              <path
                d="M12,0A12,12,0,1,0,24,12,12.013,12.013,0,0,0,12,0Zm8.647,7H17.426a19.676,19.676,0,0,0-2.821-4.644A10.031,10.031,0,0,1,20.647,7ZM16.5,12a10.211,10.211,0,0,1-.476,3H7.976A10.211,10.211,0,0,1,7.5,12a10.211,10.211,0,0,1,.476-3h8.048A10.211,10.211,0,0,1,16.5,12ZM8.778,17h6.444A19.614,19.614,0,0,1,12,21.588,19.57,19.57,0,0,1,8.778,17Zm0-10A19.614,19.614,0,0,1,12,2.412,19.57,19.57,0,0,1,15.222,7ZM9.4,2.356A19.676,19.676,0,0,0,6.574,7H3.353A10.031,10.031,0,0,1,9.4,2.356ZM2.461,9H5.9a12.016,12.016,0,0,0-.4,3,12.016,12.016,0,0,0,.4,3H2.461a9.992,9.992,0,0,1,0-6Zm.892,8H6.574A19.676,19.676,0,0,0,9.4,21.644,10.031,10.031,0,0,1,3.353,17Zm11.252,4.644A19.676,19.676,0,0,0,17.426,17h3.221A10.031,10.031,0,0,1,14.605,21.644ZM21.539,15H18.1a12.016,12.016,0,0,0,.4-3,12.016,12.016,0,0,0-.4-3h3.437a9.992,9.992,0,0,1,0,6Z"
              />
            </svg>
            <a :href="data.link">{{ data.link }}</a>
            <span>сайт</span>
          </div>
          <div class="card__item" v-if="data.mail != ''">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="512" height="512">
              <path
                d="M12,0A12.013,12.013,0,0,0,0,12c-.126,9.573,11.159,15.429,18.9,9.817a1,1,0,1,0-1.152-1.634C11.3,24.856,1.9,19.978,2,12,2.549-1.266,21.453-1.263,22,12v2a2,2,0,0,1-4,0V12C17.748,4.071,6.251,4.072,6,12a6.017,6.017,0,0,0,10.52,3.933A4,4,0,0,0,24,14V12A12.013,12.013,0,0,0,12,0Zm0,16a4,4,0,0,1,0-8A4,4,0,0,1,12,16Z"
              />
            </svg>
            <a :href="'mailto:' + data.mail">{{ data.mail }}</a>
            <span>email</span>
          </div>
        </div>

        <div class="information" data-aos="fade-up">
          <p v-for="(item, index) in data.description.split('\n')" :key="index">{{ item }}</p>
        </div>

        <div class="gallery" v-if="gallery.length > 0" data-aos="fade-up">
          <Carousel :settings="settings" :breakpoints="breakpoints">
            <Slide v-for="slide in gallery" :key="slide">
              <div class="carousel__item">
                <img :src="slide" />
              </div>
            </Slide>

            <template #addons>
              <Navigation />
            </template>
          </Carousel>
        </div>

        <div class="video" v-if="data.video" data-aos="fade-up">
          <video controls loop>
            <source :src="data.video" type="video/mp4" />
          </video>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
  import { useRoute } from "vue-router";
  import axios from "axios";
  import { onBeforeMount, ref, defineComponent } from "vue";
  import Loader from "../components/global/Loader";
  import { Carousel, Navigation, Slide } from "vue3-carousel";

  export default defineComponent({
    components: { Loader, Carousel, Slide, Navigation },

    data: () => ({
      settings: {
        itemsToShow: 1,
        snapAlign: "center",
      },
      breakpoints: {
        720: {
          itemsToShow: 2,
          snapAlign: "start",
        },
        1240: {
          itemsToShow: 3,
          snapAlign: "start",
        },
      },
    }),

    setup() {
      const route = useRoute();
      const id = route.query.id;

      const isLoad = ref(true);

      const data = ref({});

      const gallery = ref([]);
      let timestamp = Date.now().toLocaleString();

      const fetchData = async () => {
        try {
          let url =
            "https://sheets.googleapis.com/v4/spreadsheets/1K6j5VzAHJ-GPfuR-lhs4-8mXcpvNzkYhvw0Q-gEu97U/values/Events?key=AIzaSyBuJkVzx2UaNmFq-o7aRSAqgHLvwc5IDas";
          let get = await axios.get(url),
            header = get.data.values[0],
            rows = get.data.values.slice(1),
            formated = [];

          for (const row of rows) {
            const formattedRow = {};

            header.forEach((e, i) => {
              formattedRow[e.toLowerCase()] = row[i];
            });

            formated.push(formattedRow);
          }

          data.value = formated.find((e) => e.id == id);

          if (data.value.photo_gallery) {
            gallery.value = data.value.photo_gallery.split(";");
          }

          document.title = data.value.name + " | Відкрий Бердичів";

          setTimeout(() => {
            isLoad.value = false;
          }, 500);
        } catch (e) {
          console.log(e);
        }
      };

      onBeforeMount(() => fetchData());

      return { isLoad, data, gallery, timestamp };
    },
  });
</script>
<style lang="scss" scoped>
  .place {
    .lodad {
      display: grid;
      place-items: center;
      width: 100%;
      height: 580px;
    }

    .content {
      .header {
        position: relative;
        display: flex;
        align-items: flex-end;
        width: 100%;
        min-height: 720px;
        margin-top: -150px;
        overflow: hidden;
        padding: 150px 0 100px;

        .main__image {
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

          &::after {
            position: absolute;
            content: "";
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: linear-gradient(
              0deg,
              rgba($color: #000, $alpha: 0.8) 0%,
              rgba($color: #000, $alpha: 0.3) 50%,
              rgba($color: #000, $alpha: 0) 100%
            );
          }

          img {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
          }
        }

        .main__title {
          display: block;
          width: 100%;
          max-width: 1140px;
          margin: 0 auto;
          padding: 0 3vw;
          color: #fff;
          z-index: 1;

          p.name {
            font-size: 1.6em;
            font-weight: bold;
          }

          p.category {
            float: left;
            padding: 0.1em 0.6em;
            background: rgba($color: #fff, $alpha: 0.5);
            border-radius: 999px;
          }
        }
      }

      .card {
        position: relative;
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
        place-items: center;
        grid-gap: 1em;
        width: 100%;
        min-height: 120px;
        background: #fff;
        margin-top: -60px;
        padding: 1em;
        box-shadow: rgba(0, 0, 0, 0.1) -4px 9px 25px -6px;

        .card__item {
          width: 100%;

          svg {
            width: 12px;
            height: 12px;
            fill: $mid-grey;
          }

          span {
            text-transform: uppercase;
            font-size: 0.6em;
            color: $mid-grey;
          }

          a,
          p {
            font-weight: bold;
            font-size: 0.9em;
            white-space: pre-wrap;
            overflow: hidden;
            text-overflow: ellipsis;
            color: #333;
          }

          a:hover {
            color: $acs;
          }
        }
      }

      .information {
        margin-top: 3em;
        padding: 0 5vw;

        p {
          white-space: pre-wrap;
          text-indent: 1rem;
        }
      }

      .gallery {
        margin-top: 3em;

        .carousel__item {
          width: 80vw;
          max-width: 520px;
          padding: 0 0.5em;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }

      .video {
        width: 90vw;
        max-width: 640px;
        margin: 3em auto 0;

        video {
          width: 100%;
        }
      }
    }
  }
</style>
